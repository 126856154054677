import PageTab from "App/Components/UI/PageTab";
import { useStore } from "App/hooks-store/store";
import { useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import "./GalleryManagement.scss"


const GalleryManagement = () => {
    const { id } = useParams()
    const tabs = [{
        link: `${id ? `gallery/${id}` : 'gallery'}`,
        label: 'Collector Galleries'
    }, {
        link: `${id ? `creator-gallery/${id}` : 'creator-gallery'}`,
        label: 'Creator Galleries'
    }]
    const dispatch = useStore(false)[1];
    useEffect(() => {
        dispatch('setPageTitle', 'Gallery Management')
    }, [dispatch])

    return (
        <div className="gallery-wrapper">
            <PageTab tabs={tabs} />
            <div className="py-4">
                <Outlet />
            </div>
        </div>
    )
}
export default GalleryManagement;