import "../Icons.scss";

const AssetBackedIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M14 10.6693L9.99787 14.6693H2.66667C2.29848 14.6693 2 14.3708 2 14.0026V2.0026C2 1.63442 2.29848 1.33594 2.66667 1.33594H13.3333C13.7015 1.33594 14 1.63442 14 2.0026V10.6693ZM12.6667 10.0026V2.66927H3.33333V13.3359H9.33333V10.0026H12.6667Z"
          fill="#F7F8F9"
        />
      </svg>
    </div>
  );
};

export default AssetBackedIcon;
