import SearchFilter from "App/Components/UI/SearchFilter";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import User from "App/Components/UI/User";
import tableSort from "App/helpers/tableSort";
import React, { useCallback, useEffect, useState } from "react";
import ButtonLink from "App/Components/UI/ButtonLink";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import Pagination from "App/Components/UI/Pagination";
import Popup from "App/Components/UI/Popup";
import { LIST_LIMIT } from "App/constants";
import ListClickUsers from "../ReferralList/Components/ListClickUsers";

const paginationConfig = {
    perPage: LIST_LIMIT
}
const InviteList = React.memo(() => {
    const [dateMenu, setDateMenu] = useState([{
        label: 'Date Range',
        action: 'dateRange',
        selected: false,
        popup: true
    }
    ])
    const [initial, setInitial] = useState(true);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState(1);
    const { triggerAPI } = useHttp();
    const dispatch = useStore(false)[1];
    const [list, setList] = useState([]);
    const [search, setSearch] = useState('')
    const [sort, setSort] = useState({ direction: 'DESC', sortKey: 'createdAt' });
    const [selectedData, setSelectedData] = useState({})
    const [selectedDate, setSelectedDate] = useState('')
    const [showCreateUserPopup, setShowCreateUserPopup] = useState({ mode: false });

    const onSortHandler = useCallback((sortKey) => {
        const dir = sort.sortKey === sortKey ? sort.direction : '';
        const direction = tableSort(dir)
        setSort({ direction, sortKey })
    }, [sort]);


    const onFilterData = (e) => {
        setSelectedDate(e)
    }
    const searchHandler = (e) => {
        setSearch(e)
    }
    const userCreationHandler = (e) => {
        setSelectedData(e.row)
        setShowCreateUserPopup({ mode: true })
    }

    const onCloseCreateUser = (data) => {
        setShowCreateUserPopup({ mode: false })
        if (data) {
            getList()
        }
    }

    const onPageChangeHandler = (page) => {
        setPage(page)
        const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
    }
    const getListResult = useCallback((res) => {
        dispatch('hideSpinner');
        const { list, pageMeta } = res.data
        setList(list)
        setTotalPage(pageMeta.totalItems)
    }, [dispatch])


    const getList = useCallback(() => {
        dispatch('showSpinner');
        let filter;
        if (sort.direction || selectedDate.from) {
            filter = {
                sortBy: sort.sortKey,
                orderBy: sort.direction,
                from: selectedDate.from,
                to: selectedDate.to,
                type:["invite"]
            }
        }
        else {
            filter = {type:["invite"]}
        }       
        triggerAPI({
            url: `referral/get`, data: {
                page: page,
                items: paginationConfig.perPage,
                search: `${search}`, filter
            }, method: 'post'
        }, getListResult);
    }, [dispatch, getListResult, page, search, selectedDate, sort, triggerAPI])

    useEffect(() => {
        if (page !== 1) {
            setPage(1)
        }
        else {
            getList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sort, search, selectedDate])

    useEffect(() => {
        if (!initial) {
            getList();
        }
        setInitial(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page])




    return (
        <div>
            <div className="flex justify-between items-center mb-4">
                <div className='search-filter'>
                    <SearchFilter position={{ right: 80, top: 10 }} onSearch={searchHandler} setActionMenu={setDateMenu} actionMenu={dateMenu} onFilterData={onFilterData} />
                </div>

            </div>
            <div className="mb-40">
                <Table>
                    <thead className="text-left">
                        <tr>
                            <th className="sticky left-0 bg-[#272832]">
                                <TableHeader label="User"/>
                            </th>
                            <th>
                                <TableHeader label="Referral code" />
                            </th>
                            <th>
                                <TableHeader label="Type"/>
                            </th>
                            <th>
                                <TableHeader label="Clicks"/>
                            </th>
                            <th>
                                <TableHeader label="Shared Url"/>
                            </th>
                            <th>
                                <TableHeader label="CreatedAt" sort={sort} sortKey="createdAt" onSort={onSortHandler}/>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {list?.map((referral) => <tr key={referral.id}>
                            <td className="sticky left-0 bg-[#14141C] z-[1]">
                                <User label={referral?.user?.username} src={referral?.user?.profilePicUrl256} className="user-table" />
                            </td>
                            <td>
                            {referral?.referralId}
                            </td>
                            <td>
                            {referral?.type}
                            </td>
                            <td>
                            {referral?.referralActions.length}&nbsp;
                            {referral?.referralActions.length && <ButtonLink row={referral.referralActions} label="View" onClick={userCreationHandler} /> || ""}
                            </td>
                            <td>
                                {referral?.url}
                            </td>
                            <td>
                            {new Date(referral.createdAt)?.toLocaleString()}
                            </td>
                            
                        </tr>)}
                    </tbody>
                </Table>
                {!list?.length &&
                    <div className="text-center no-rows pt-4">
                        <p>No data</p>
                    </div>}
                <div className="mt-6">
                    <Pagination itemsPerPage={paginationConfig.perPage} total={totalPage} rows={list.length} onPageChange={onPageChangeHandler} selected={page} />
                </div>
                <Popup show={showCreateUserPopup}
                    style={{ 'maxWidth': '51.42rem' }}
                    title={`User list`} cssClass="user-create-pop" onClose={onCloseCreateUser}>
                    <ListClickUsers triggerClose={onCloseCreateUser} row={selectedData} />
                </Popup>

            </div>
        </div>
    )
})
export default InviteList;