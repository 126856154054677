
import { deepCopy, getMetadata } from "App/helpers/utilities";
import useHttp from 'App/hooks/use-http';
import { useCallback, useMemo } from "react";
import useMarketplace from "./use-marketplace";
import storage from "App/helpers/storage";
import { collectionsWithFrameAdded } from "App/helpers/collections";

const { useStore } = require("App/hooks-store/store");

const useCollectionLoader = () => {
    const [store, dispatch] = useStore();    
    const { collections, tokens, overAllMarketplaceAssets, userAddedFrameList, collectionDetails, mediaFiles } = store
    let { triggerAPI } = useHttp();
    const { updateMarketplaceKey } = useMarketplace();
    const triggerFetch = async (assetsList, collectionsetfunction, loadingstatusfunction, type, callBack = null, refetchExistingAssets = false) => { 
        let assetList = assetsList ? [...assetsList] : [];
        let assetids = assetList;
        let functionToExclude = ['setCurrentGalleryAssets', 'setSingleCollection', 'setTokenCollection']
        if (!refetchExistingAssets && !functionToExclude?.includes(collectionsetfunction)) { 
            let collectionData = collections ? [...collections] : [];
            if(type === 'token')
                collectionData = tokens ? [...tokens] : [];
            if(type === 'media')
                collectionData = mediaFiles ? [...mediaFiles] : [];
            if(collectionsetfunction === 'setOverAllMarketplaceAssets')
                collectionData = overAllMarketplaceAssets ? [...overAllMarketplaceAssets] : []
            //to eliminate existing assetid
            // eslint-disable-next-line array-callback-return
            const eliminated = [...assetList]?.filter(item => {
                let temp = collectionData?.find(aData => aData?.asset === item?.asset)
                if(!temp)
                    return item;
            })   
            assetids = eliminated;
        }
        // eslint-disable-next-line no-unused-vars
        const newAssetList = assetids?.map(item => { return {
            asset_name: item?.asset_name?.toLowerCase(), 
            policy_id: item?.policy_id?.toLowerCase(), 
            contractAddress: item?.contractAddress, 
            chain: item?.chain,
            update: item?.update            
        }}) // for new API
        if (newAssetList?.length> 0)
        {
            try {
                
                await getImageDetailsChunked({ newAssetList, collectionsetfunction, loadingstatusfunction, assetList, type, callBack });    
            } catch (error) {
                console.log("Error in getting collection chunks")
                console.log(error)
            }
        } else if(!!callBack) {
                callBack({data: []})
        }
        dispatch(loadingstatusfunction, 'finished') 
        // if (type === 'connectedWallet' && (collections?.length ?? 0) === 0)
        // {            
        //     walletAddHandler(currentWallet.address, currentWallet.chain, () => {
        //         dispatch('setTriggerCollection', true);                
        //         navigate('/curate/collections/my-collections');
        //     });  
        // } 
    }    

    const sliceCollectionAPI = async (assetsList, collectionsetfunction="setCollections", loadingstatusfunction="updateCollectionLoadingStatus", type = '', callBack = null,refetchExistingAssets=false) => { 
            //dispatch(loadingstatusfunction,"in-progress");
            await triggerFetch(assetsList, collectionsetfunction, loadingstatusfunction, type, callBack,refetchExistingAssets)   
    }

    const getImageDetailsChunked = async ({newAssetList, collectionsetfunction, loadingstatusfunction, assetList = [], type = '', callBack = null}) => {                        
        const data = {            
            assetids: newAssetList
        }
        let fetchedAssets = [];
        
        if (newAssetList?.length > 0)
        {
            const token = storage().get('token')
            let headers = {}
            if(token)
            headers = {
                Authorization: `Bearer ${token}`,
                // isb2c: true // if we pass this, the api was getting 401 error
            }
    
            headers['Content-Type'] = 'application/json';
    
            const requestOptions = {
                method: 'POST',
                headers: headers,
                body: JSON.stringify(data)
            };
            
            if (type === 'connectedWallet')
            {
                dispatch("updateCollectionLoadTotal", newAssetList?.length);
                dispatch("updateCollectionLoadProgress", 0)    
            }
            
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/user/nft-collection/asset/detail?networkType=MAINNET&blockchainTypeId=1`, requestOptions);    
            
            const reader = response.body.getReader();

            dispatch(loadingstatusfunction, 'in-progress')

            let onlycommas = new RegExp(
                '^[,]+$'
            );
            const decoder = new TextDecoder();
            let chunks = [];
            let collectionretreived = [];
            let lastupdate = new Date().getTime();
            let progress = 0;
            while (true) {                
                const {done, value} = await reader.read();                                    
                if (value)
                {                                       
                    let str = decoder.decode(value);                    
                    try {                           
                        if (str !== "" && !onlycommas.test(str))
                        {                               
                            chunks.push(str);

                            //test for valid collection
                            try {
                                str = chunks.join('');
                                if (str.startsWith(","))
                                {
                                    str=str.substring(1);
                                }
                                
                                if (!str.startsWith("["))
                                {
                                    str = '[' + str;    
                                }
                                if (!str.endsWith("]"))
                                {
                                    str = str + ']';                        
                                }
                                
                                let col = JSON.parse(str);
                                
                                let colmeta = col.map(x => {
                                    if(assetList?.length) {
                                        //reassign walletid based on the client wallet list, with new ids
                                        x.walletId = assetList?.find(assetData=> x.asset?.toLowerCase() === `${assetData.chain==='cardano'?'':assetData.chain}${assetData.contractAddress}${assetData.asset_name}`.toLowerCase())?.walletId;
                                    }
                                    return getMetadata(x, 'isFavouriteNFTCollection')
                                })
                                chunks = [];
                                collectionretreived = collectionretreived.concat(colmeta);
                                //append items into collection
                                //dispatch("setCollections", { loading: false, data: colmeta, count: 0 })                                  
                            } catch (error) {
                                if (!error.message.startsWith("Unterminated string in JSON") 
                                    && !error.message.startsWith("Unexpected token")
                                    && !error.message.startsWith("Expected ',' or '}'"))
                                {
                                    console.error(error);
                                }                                
                                //wasn't valid for parsing, so let the next loop add another chunk and retry
                                //console.log("Not valid for parsing yet...")
                            }
                            

                                                                                    
                        }
                        else
                        {
                            console.log("empty str");
                        }
                    } catch (error) {
                        console.log("ERROR parsing json" + error)
                        console.log(str)
                    } 
                    
                }                
                
                //code to only update the UI every 500ms, as otherwise the max update depth is exceeded
                if (lastupdate < new Date().getTime()-500)
                {
                    lastupdate = new Date().getTime();
                    
                    dispatch(collectionsetfunction, { loading: true, data: collectionretreived, count: 0 }) 
                    dispatch('setOverAllAssets', deepCopy(collectionretreived))
                    if (type === 'connectedWallet')
                    {
                        progress += collectionretreived?.length;
                        dispatch("updateCollectionLoadProgress", progress)                    
                    }
                    fetchedAssets = fetchedAssets.concat(collectionretreived);
                    collectionretreived = [];
                }
                if (done) {
                    //append items into collection
                    dispatch(collectionsetfunction, { loading: false, data: collectionretreived, count: 0 })
                    dispatch('setOverAllAssets', deepCopy(collectionretreived))
                    if (type === 'connectedWallet')
                    {
                        progress += collectionretreived?.length;
                        dispatch("updateCollectionLoadProgress", progress)
                    }
                    fetchedAssets = fetchedAssets.concat(collectionretreived);
                    break;                
                }                
            }
            callBack && callBack({data: fetchedAssets})
            if (type === 'connectedWallet')
            {
                dispatch("updateCollectionLoadTotal", 0);
                dispatch("updateCollectionLoadProgress", 0);    
            }
            dispatch(loadingstatusfunction, 'finished')                   
        }
        else 
        {            
            console.log("ERROR - convert call to getImageDetails to use assetids")
        }
    }
    

    const sliceArtBankAPI = (assetList) => {
        sliceCollectionAPI(assetList, "setArtBank", "updateArtBankLoadingStatus");        
    }

    const sliceSingleCollectionAPI = ({assetList, type, callBack = null}) => {
        sliceCollectionAPI(assetList, "setSingleCollection", "updateSingleCollectionLoadingStatus", type, callBack);    
    }

    const sliceConnectedWalletCollectionAPI = (assetList) => {
        sliceCollectionAPI(assetList, "setConnectedWalletCollection", "updateConnectedWalletCollectionLoadingStatus", 'connectedWallet', undefined, true);    
    }

    const sliceTokenAPI = (assetList) => {
        sliceCollectionAPI(assetList, "setTokenCollection", "updateTokenLoadingStatus", 'token');        
    }

    const sliceMediaFilesAPI = (assetList,isEdit=false) => {
        sliceCollectionAPI(assetList, "setMediaFilesCollection", "updateMediaFilesLoadingStatus", 'media',null,isEdit);        
    }

    const refreshWallets = (isAutoWalletRefresh = false) => {        
        dispatch("updateCollectionLoadingStatus","in-progress")
        triggerAPI({
            url: 'user/wallets/assets/get', method: 'get'
        }, async (res) => {            
            let wallets = Object.keys(res?.data?.wallets);
            let deletedCount = 0;
            let addedCount = 0;
            let updatedCount = 0;
            for (let i = 0; i < wallets?.length; i++) {
                const wallet = res.data.wallets[wallets[i]];  
                let chain = wallet.blockchainType?.code;
                let assets = wallet.asset_list.map(a=> { return {
                    asset_name: a.asset_name?.toLowerCase(),
                    policy_id: a.policy_id?.toLowerCase(),
                    chain: chain,
                    walletId: wallet.walletId
                }});                
                let existingAssets = collections.filter(a=>
                    a.walletId === wallet?.walletId && chain === (a.blockchainType?.code??a.onchain_metadata?.chain)
                );
                let newAssets = assets.filter(na=>{
                    let index = existingAssets.findIndex(ea=>
                        ea.asset === `${na.chain==='cardano'?'':na.chain}${na.policy_id}${na.asset_name}`)                        

                    return index===-1;
                }).map(a=>{
                    return {
                        ...a,
                        asset: `${a.chain==='cardano'?'':a.chain}${a.policy_id}${a.asset_name}`.toLowerCase(),
                        contractAddress: a.policy_id
                    }
                });

                let deletedAssets = existingAssets.filter(ea=>{
                    let index = assets.findIndex(na=>
                        ea.asset === `${na.chain==='cardano'?'':na.chain}${na.policy_id}${na.asset_name}`)                        

                    return index===-1;
                });                

                let updatedAssets = existingAssets.filter(ea=>{                    
                    let index = wallet.asset_list.findIndex(wa=>
                        ea.asset === `${chain==='cardano'?'':chain}${wa.policy_id}${wa.asset_name}` &&
                        (new Date(wa.assetDetailsUpdatedAt) > new Date(ea.assetDetailsUpdatedAt) || new Date(wa.collectionUpdatedAt) > new Date(ea.collectionUpdatedAt))
                    );

                    return index>-1;
                })

                addedCount += newAssets?.length??0;
                deletedCount += deletedAssets.length??0;
                updatedCount += updatedAssets.length??0;

                if (updatedAssets.length > 0)
                {
                    //for changed updated assets, just add them to update                    
                    newAssets = [...newAssets,...updatedAssets.map(ua=>{return{...ua,policy_id:ua.policy,chain:ua.blockchainType.code}})]
                }
                if (deletedAssets?.length > 0)
                {                    
                    await dispatch("deleteCollections", deletedAssets);
                    //This code shouldn't be neccessary. The dispatch line above does the same thing.
                    //Even though I await, the collections object isn't updated locally with the deletions when dealing with multiple wallets.
                    //So we manually remove the items, so that the trigger fetch below doesn't see them still present in collections.
                    for (let i = 0; i < deletedAssets.length; i++) {
                        const collectionToDelete = deletedAssets[i];
                        const collectionIndex = collections.indexOf(collectionToDelete);
                        
                        if (collectionIndex>-1)
                        {
                            collections.splice(collectionIndex,1);    
                        }                
                    }                    
                }                
                if (newAssets?.length > 0)
                {                                        
                    await triggerFetch(newAssets, "setCollections", "updateCollectionLoadingStatus", '', handleFetchedAssets, true);                                                                         
                }                
            }
            dispatch("updateCollectionLoadingStatus","finished");
            let message = `Collections refresh complete.`;
            if (addedCount===0 && deletedCount===0 && updatedCount===0)
            {
                message += "\r\nNo changes found.";   
            }
            else
            {
                if (addedCount===1)
                {
                    message += "\r\n1 new piece added.";   
                }
                else if (addedCount > 1)
                {
                    message += `\r\n${addedCount} new pieces added.`;
                }
                if (deletedCount===1)
                {
                    message += "\r\n1 piece removed.";   
                }
                else if (deletedCount > 1)
                {
                    message += `\r\n${deletedCount} pieces removed.`;   
                }
                if (updatedCount===1)
                {
                    message += "\r\n1 piece updated.";   
                }
                else if (updatedCount > 1)
                {
                    message += `\r\n${updatedCount} pieces updated.`;   
                }                
            }
            if (!isAutoWalletRefresh || addedCount > 0 || updatedCount > 0 || deletedCount > 0)
            {
                dispatch('showToast', { toast: { toastMode: 'success', message } })
            }            
        }, (er) => {
            console.log("ERROR ",er)            
        })
        const handleFetchedAssets = ({data}) => {
            const collectionIds = [];
            data?.forEach(item => {
                if(!collectionIds?.includes(item?.policy)) 
                    collectionIds?.push(item?.policy)

            })
            if(collectionIds?.length) 
                getCollectionDetails({collectionIds, refetch: true});
        };
    }
    const refreshDone = (result)=>{
        if (result?.data?.length)
        {
            let indexof = collections.findIndex(p=>p.asset === result.data[0].asset);
            if (indexof>-1)
            {
                collections[indexof] = result.data[0];                
            }    
        }        
    }
    const refreshPiece = async (piece)=>{        
        let collectionPiece = collections.filter(p=>p.asset === piece.asset)?.[0];
        if (collectionPiece)
        {                                       
            const newAssets = [collectionPiece].map(a=>{
                return {
                    ...a,
                    contractAddress: a.contractAddress,
                    policy_id: a.contractAddress,
                    chain:a.blockchainType.code,
                    update: true
                }
            });                     
            await triggerFetch(newAssets, "setCollections", "updateCollectionLoadingStatus", '', refreshDone, true);
        }        
    }
    const getCollectionDetails = useCallback(({collectionIds = [], callBack = null, refetch = false}) => {
        let collectionResultArray = [], idsToFetchCollection = [];
        const findCollectionById = (id) => {
            return collectionDetails?.find(item => item?.policy === id)
        }
        collectionIds?.forEach(collectionId => {
            const detail = findCollectionById(collectionId);
            if(!!detail && !refetch) {
                collectionResultArray.push(detail)
            } else {
                idsToFetchCollection.push(collectionId)
            }
        });
        if(idsToFetchCollection?.length) {
            triggerAPI({
                url: `community/collections-details`,
                method: "post",
                data: {collectionIds: idsToFetchCollection}
            }, ({data}) => {
                collectionResultArray = [...collectionResultArray, ...data];
                dispatch('setCollectionDetails', [...data, ...collectionDetails])
                callBack && callBack(collectionResultArray);
             });
        }  else {
            callBack && callBack(collectionResultArray);
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [collectionDetails])
    
    const userCollections = useMemo(() => {
        let newList = collectionsWithFrameAdded({collections, mediaFiles, userAddedFrameList});
        return newList
    }, [collections, userAddedFrameList, mediaFiles])
    
    const allUserCollection = useMemo(() => {
        return [
            ...(collections || []),
            ...(mediaFiles || []),
            ...(tokens || [])
          ]
    }, [collections, tokens, mediaFiles])

    const getAssetList = useCallback(({assetIds = [], isMarketplace = false, collectionId = '', callBack = null}) => {
       
        let marktplaceAssets = assetIds?.map(a => { return { asset: a.policy_id ?? collectionId + a.asset_name, policy_id: a.policy_id ?? collectionId,  asset_name: a.asset_name, chain: a.chain, contractAddress: a?.contractAddress}})
        
        sliceSingleCollectionAPI({assetList: marktplaceAssets, callBack: ({data}) => {
            let temp = JSON.parse(JSON.stringify(data)); // Create a deep copy of data
            if(isMarketplace)
                temp = updateMarketplaceKey(temp, assetIds)
            callBack && callBack([...temp])            
        }});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    return {          
        sliceCollectionAPI,
        sliceArtBankAPI,
        sliceSingleCollectionAPI,        
        sliceConnectedWalletCollectionAPI,        
        sliceTokenAPI,
        sliceMediaFilesAPI,
        refreshWallets,
        getCollectionDetails,
        getAssetList,
        userCollections,
        allUserCollection,
        refreshPiece
    }
}
export default useCollectionLoader;