import { removeDuplicateByElement } from "App/helpers/utilities";

const { initStore } = require("./store")
const configureStore = () => {
    const actions = {
        setSingleCollection: (current, singleCollection) => {
            let currentData = current?.singleCollection ?? [] ;
            const data = [...currentData, ...singleCollection.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {                
                singleCollection: finalData                
            }
        },
        resetSingleCollection: (current, singleCollection) => {
            return {                
                singleCollection: []                
            }
        },
        
        updateSingleCollectionLoadingStatus: (current,status) => {
            return {
                singleCollectionLoadingStatus: status
            }
        },      
        setConnectedWalletCollection: (current, singleCollection) => {
            let currentData = current?.connectedWalletCollection ?? [] ;
            const data = [...currentData, ...singleCollection.data];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {                
                connectedWalletCollection: finalData                
            }
        },
        resetConnectedWalletCollection: (current, singleCollection) => {
            return {                
                connectedWalletCollection: []                
            }
        },
        
        updateConnectedWalletCollectionLoadingStatus: (current,status) => {
            return {
                connectedWalletCollectionLoadingStatus: status
            }
        }       ,
        setCollections: (current, collections) => {
            let currentData = current?.collections ?? [] ;
            const data = [...collections.data, ...currentData];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                collectionsLoaded: 0,
                collectionsLoading: collections.loading,
                collections: finalData,
                collectionsCount: collections.count
            }
        },
        deleteCollections: (current, collectionsToDelete) => {
            let currentData = current?.collections ?? [] ;
            if (collectionsToDelete?.length > 0)
            {                
                for (let i = 0; i < collectionsToDelete.length; i++) {
                    const collectionToDelete = collectionsToDelete[i];
                    const collectionIndex = currentData.indexOf(collectionToDelete);
                    
                    if (collectionIndex>-1)
                    {
                        currentData.splice(collectionIndex,1);    
                    }                
                }
            }
            
            const finalData = removeDuplicateByElement(currentData, 'asset');
            return {
                collectionsLoaded: 0,
                collectionsLoading: currentData.loading,
                collections: finalData,
                collectionsCount: currentData.count
            }
        },
        resetCollections: (current, collections) => {
            return {
                collectionsLoaded: 0,
                collectionsLoading: false,
                collections: [],
                collectionsCount: 0
            }
        },
        setAssetsList: (current, assetsList) => {
            return {
                collectionAssetList: assetsList,

            }
        },
        updateCount: (current) => {
            if (current.collectionsLoaded < current.collectionsCount)
                return {
                    collectionsLoaded: current.collectionsLoaded + 1
                }
        },
        updateCollectionLoadingStatus: (current,status) => {
            return {
                collectionLoadingStatus: status
            }
        },        
        updateCollectionRawData: (current,data) => {
            return {
                collectionRawData: data
            }
        },        
        updateCollectionAssetCount: (current, data) => {
            return {
                collectionAssetCount: data
            }
        },        
        updateCollectionWalletData: (current, data) => {
            return {
                collectionWalletData: data
            }
        },        
        reFetch: (current) => {
            return {
                reFetchCollections: {mode:true}
            }
        },
        favToggle: (current, object) => {
            const updatedCollections = current?.collections.map(collec => {
                if (collec.policy === object.policy) {
                    return {
                        ...collec,
                        isCollectionFavourite: object?.value
                    };
                }
                return collec;
            });
            return {
                collections: updatedCollections
            }
        },
        collectionVisibility: (current, object) => {
            const updatedCollections = current?.collections.map(collec => {
                if (collec.policy === object.policy) {
                    return {
                        ...collec,
                        isCollectionHided: object?.value
                    };
                }
                return collec;
            });
            return {
                collections: updatedCollections
            }
        },
        favAssetToggle: (current, object) => {
            let find = current.collections.find(collec => collec.asset === object.asset)
            if (find)
                find.isFavourite = object.value
            return {
                collections: current.collections
            }
        },
        updateUsername: (current, newUsername) => {
            return {
                username: newUsername
            }
        },
        setTriggerCollection: (current, value) => {
            return {
                triggerCollection: value
            }
        },
        setCurrentCollectionTab: (current, tab) => {
            return {
                currentCollectionTab: tab
            }
        },
        setIsLoginInProgress: (current, value) => {
            return {
                isLoginInProgress: value
            }
        },
        setCollectionDetails: (current, value) => {
            const finalData = removeDuplicateByElement(value, 'collectionId');
            return {
                collectionDetails: finalData
            }
        },
        setIsFetchingFromCache: (current, value) => {
            return {
                isFetchingFromCache: value
            }
        },
        setShowWalletPopup: (current, status) => {
            return {
                showWalletPopup: status
            }
        },



        updateCollectionLoadTotal: (current,total) => {
            return {
                collectionLoadTotal: total
            }
        },       
        updateCollectionLoadProgress: (current,progress) => {
            return {
                collectionLoadProgress: progress
            }
        },  
        setOverAllAssets : (current, assets) => {
            let currentData = current?.overAllAssets ?? [] ;
            const data = [...currentData, ...assets];
            const finalData = removeDuplicateByElement(data, 'asset');
            return {
                overAllAssets: finalData
            }            
        },
        setCommunityThumbnailData : (current, data) => {
            if(!!data) {
                let currentData = current?.communityThumbnailData ? current?.communityThumbnailData : [] 
                data = [...currentData, ...data]
            }
            return {
                communityThumbnailData: data
            }            
        },
    }
    initStore(actions, {
        overAllAssets: [],
        singleCollection: [],        
        singleCollectionLoadingStatus:'', 
        connectedWalletCollection: [],        
        connectedWalletCollectionLoadingStatus:'',    
        currentCollectionTab: 'all-wallets',
        isLoginInProgress: false,
        triggerCollection: false,
        collectionAssetList: [],
        collectionsLoading: false,
        collectionsLoaded: 0,
        collectionAssetCount: 0,
        collections: [],
        collectionsCount: 0,
        collectionLoadingStatus:'finished', //defaulted to finished so cached collections will display
        collectionRawData:[],
        collectionDetails:[],
        collectionWalletData: [],
        isFetchingFromCache: true,
        reFetchCollections: {mode:false},
        username:'',
        showWalletPopup:false,
        communityThumbnailData: null,
        schemaVersionNumber: 17 //increment this number when you change the collection or token schema, it will trigger local collection data to be refetched
    });
}
export default configureStore;