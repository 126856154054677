import React, { useEffect, useState } from "react";
import "./ImageWithLoader.scss";
import SkeletonLoader from "App/Components/UI/SkeletonLoader";
import defaultImage from "Assets/Icons/logo-square.svg";

const ImageWithLoader = ({ image = defaultImage,loader }) => {
  const [imgLoading, setImageLoading] = useState(true);
  useEffect(() => {
    setImageLoading(loader)
  }, [loader]);
  useEffect(() => {
    const img = new Image();
    img.src = image;
    setImageLoading(true);
    img.onload = () => {
      setImageLoading(false);
    };
  }, [image]);
  return (
    <div className={`image-with-loader-wrap`}>
      {imgLoading ? (
        <div className={"image-wrap"}>
          <SkeletonLoader
            height={`100%`}
            baseColor={"var(--cu-black-100)"}
            highlightColor={"var(--cu-black-110)"}
          />
        </div>
      ) : (
        <div className="overflow-hidden img-wrapper">
          <img
            className=" w-full h-full object-cover cursor-pointer"
            src={image}
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default ImageWithLoader;
