/* eslint-disable react-hooks/exhaustive-deps */
import User from "App/Components/UI/User";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import Table from "App/Components/UI/Table";
import "./ListClickUsers.scss";

const ListClickUsers = (props) => {
  return (
    (
          <Table>
            <thead className="text-left">
              <tr>
                <th className="sticky left-0">
                  <TableHeader label="User" />
                </th>
                <th >
                  <TableHeader label="Ip" />
                </th>
                <th >
                  <TableHeader label="Date" />
                </th>
              </tr>
            </thead>
            <tbody>
              {props.row?.map((usr) => (
                <tr
                  key={usr.id}
                  className={`active`}
                >
                  <td
                    className="sticky left-0"
                  >
                    <User
                      label={usr?.user?.username}
                      src={usr?.user?.profilePicUrl256}
                      className="user-table"
                    />
                  </td>
                  <td>{usr?.maskedIp}</td>
                  <td>{new Date(usr?.updatedAt)?.toLocaleString()}</td>

                </tr>
              ))}
            </tbody>
          </Table>
        )
         
  );
};

export default ListClickUsers;
