import "../Icons.scss";

const BoxEyeInVisibleIcon = ({ className, dataIndex, dataPolicy, onClick = () => { } }) => {
    return (
        <div
            className={`icon-wrapper cursor-pointer stroke-color  ${className}`}
            data-index={dataIndex}
            data-policy={dataPolicy}
            onClick={onClick}
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#191D23" />
                <path d="M10.9333 7.16002C11.3922 7.05261 11.862 6.99892 12.3333 7.00002C17 7.00002 19.6666 12.3334 19.6666 12.3334C19.262 13.0904 18.7793 13.8032 18.2266 14.4601M13.7467 13.7467C13.5636 13.9432 13.3428 14.1008 13.0974 14.2101C12.8521 14.3194 12.5873 14.3782 12.3187 14.383C12.0502 14.3877 11.7834 14.3383 11.5344 14.2377C11.2854 14.1371 11.0592 13.9874 10.8692 13.7975C10.6793 13.6075 10.5296 13.3813 10.429 13.1323C10.3284 12.8832 10.279 12.6165 10.2838 12.348C10.2885 12.0794 10.3473 11.8146 10.4566 11.5693C10.5659 11.3239 10.7235 11.1031 10.92 10.92M16.2933 16.2934C15.1537 17.1621 13.7661 17.6433 12.3333 17.6667C7.66667 17.6667 5 12.3334 5 12.3334C5.82926 10.788 6.97942 9.43776 8.37333 8.37336L16.2933 16.2934Z" stroke="#A0ABBB" />
                <path d="M5 5L19.6666 19.6667" stroke="#A0ABBB" />
            </svg>

        </div>
    );
};

export default BoxEyeInVisibleIcon;
