const { initStore } = require("./store")

const configureStore = () => {
    const actions = {
        setCreatedByUserName: (current, createdByUserName) => {
            return {
                createdByUserName
            }
        }
    }
    initStore(actions, {
        createdByUserName: ''
    });
}
export default configureStore;