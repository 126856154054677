import SkeletonLoader from "App/Components/UI/SkeletonLoader";
import React, {
  createRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import ImageWithLoader from "App/Components/ImageWithLoader";
import "./ImageCard.scss";

const imageReducer = (state, action) => {
  switch (action.type) {
    case "image":
      return { ...state, image: false };
    default:
      return { frame: true, image: true };
  }
};
const imagesLoader = {
  image: true,
};
const ImageCard = (props) => {
  const containerRef = createRef();
  const [frameURL, setFrameURL] = useState(null);
  const [loadedFrameURL, setLoadedFrameURL] = useState(null);
  const [reload, setReload] = useState(true);
  const [count, setCount] = useState(0);
  const [imagesLoaded, stateImagesLoaded] = useReducer(
    imageReducer,
    imagesLoader
  );
  const [frameRatio, setFrameRatio] = useState(null);
  const [imageRatio, setImageRatio] = useState(null);
  const [frameWidth, setFrameWidth] = useState("100%");
  const [frameHeight, setFrameHeight] = useState("100%");
  const [frameIsSized, setFrameIsSized] = useState(false);

  useEffect(() => {
    if (!reload && count < 2) {
      setCount((prevState) => prevState + 1);
      setReload(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);
  useEffect(() => {
    stateImagesLoaded({ type: "reset" });
    if (frameURL !== loadedFrameURL) {
      setFrameIsSized(false);
    } else {
      //if we already loaded the frame, don't reset its loading state, or it will never finish loading
      stateImagesLoaded({ type: "frame" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.nft]);
  const onClickHandler = (e) => {
    if (props.onClick) props.onClick(e, props.frameDet);
  };
  const frameLoaded = useCallback(
    (e) => {
      if (frameURL) {
        setTimeout(() => {
          stateImagesLoaded({ type: "frame" });
          setLoadedFrameURL(frameURL);
          if (!props.nft) {
            stateImagesLoaded({ type: "image" });
            if (props?.setImageLoaded) props?.setImageLoaded(false);
          }
          if (props?.onFrameLoaded) props?.onFrameLoaded();
          if (e?.target)
            setFrameRatio(e.target.naturalWidth / e.target.naturalHeight);
        }, 10);
      } else {
        stateImagesLoaded({ type: "frame" });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [frameURL]
  );
  const imageLoaded = useCallback((e) => {
    setTimeout(() => {
      stateImagesLoaded({ type: "image" });
      if (props?.setImageLoaded) {
        props?.setImageLoaded(false);
      }
      if (e?.target) {
        let ratio = e.target.naturalWidth / e.target.naturalHeight;
        setImageRatio(ratio);
        if (props?.imageRatioCalculated) {
          props?.imageRatioCalculated(ratio, props?.asset);
        }
      }
      //if (!props.frameDetails) {
      //frameLoaded()
      //}
    }, 10);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const imgErrorHandler = (params) => {
    //this means retry logic fails, as we don't try to render once the first image fails, so commented out
    //setReload(false)
  };
  useEffect(() => {
    if (!props.frameDetails && !props.frame) {
      setFrameURL(null);
      frameLoaded();
    } else {
      calcFrame();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.frameDetails, props.isDetailView, props.frame]);

  const calcFrame = (width, height) => {
    let frametouse = props.frame ?? props.frameDetails?.image;
    // if (height)
    // {
    //     if (height <= 512 && height > 256 && props.frameDetails?.image512)
    //     {
    //         frametouse = props.frameDetails?.image512;
    //     }
    //     else if (height <= 256 && props.frameDetails?.image256)
    //     {
    //         frametouse = props.frameDetails?.image256;
    //     }
    // }

    // if (props.isLandscape && props.frameDetails?.s3ImageUrlLandscape)
    // {
    //     frametouse = props.frameDetails?.s3ImageUrlLandscape;
    // }

    setFrameURL(frametouse);
  };

  const updateSizes = (width, height) => {
    if (width !== 0 && height !== 0) {
      if (props.frame) {
        //if the frame image is provided, its the frame chooser, which has no correct landscape metadata
        //if there is no frame, just set the image to fill
        setFrameWidth("100%");
        setFrameHeight("100%");
      } else {
        let tempContainer = width / height;
        const isFrameOrImageRatioOne =
          Math.round(frameRatio * 100) / 100 === 1 ||
          Math.round(imageRatio * 100) / 100 === 1;

        if (isFrameOrImageRatioOne) {
          if (Math.round(tempContainer * 100) / 100 >= 1) {
            setFrameWidth("auto");
            setFrameHeight("100%");
          } else {
            setFrameWidth("100%");
            setFrameHeight("auto");
          }
        } else if (frameRatio || imageRatio) {
          const isWithinTolerance = (ratio) => ratio > 0.98 && ratio < 1.02;
          ////hack to stop repeated resizing, if the container is within tolerance (2%), then just constrain the height, rather than switching every frame
          if (!isWithinTolerance(tempContainer / (frameRatio || imageRatio))) {
            if (tempContainer >= (frameRatio || imageRatio)) {
              //wider, therefore height is constrained
              setFrameWidth("auto");
              setFrameHeight("100%");
            } else {
              //narrower, therefore width is constrained
              setFrameWidth("100%");
              setFrameHeight("auto");
            }
          }
        }
      }

      if (props.frameDetails) calcFrame(width, height);

      setTimeout(() => {
        setFrameIsSized(true);
      }, 100);
    }

    if (props?.type === "frame-option" && frameRatio > 1)
      setFrameHeight("auto");
  };

  useEffect(() => {
    updateSizes(
      containerRef.current.clientWidth,
      containerRef.current.clientHeight
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.nft,
    props?.onChainReRender,
    props.frameDetails,
    containerRef.current,
  ]);

  const getClassStyles = () => {
    let ratio = frameRatio ?? imageRatio;

    let frameimagegapratio =
      props?.frameDetails?.metaInfo?.image?.width /
        props?.frameDetails?.metaInfo?.image?.height ?? frameRatio;
    return (
      (ratio > 1 ? "landscape" : ratio === 1 ? "square" : "portrait") +
      " " +
      (frameimagegapratio < imageRatio ? "fillheight" : "fillwidth") +
      " " +
      (!frameURL ? "bareimage" : "")
    );
  };

  const getClassNames = () => {
    let ratio = frameRatio ?? imageRatio;

    let classnames =
      ratio > 1 ? "landscape" : ratio === 1 ? "square" : "portrait";

    return classnames;
  };

  const isNonImage = useCallback(() => {
    let temp = false;
    if (props?.imageFile?.length > 0) {
      let mType = props.imageFile[0]?.mType;
      let alternativeFileType = "image";
      if (mType !== "image" && alternativeFileType !== "image") temp = true;
    }
    return temp;
  }, [props?.imageFile]);

  useEffect(() => {
    if (isNonImage()) {
      if (props?.imageRatioCalculated) {
        props?.imageRatioCalculated(1, props?.asset);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.imageFile]);

  const showSkeltonCard = useMemo(() => {
    //(!frameIsSized || ((imagesLoaded.frame || imagesLoaded.image)) && frameURL) && !isNonImage() original condition
    return (
      ((!frameIsSized || imagesLoaded.frame || imagesLoaded.image) &&
        !isNonImage()) ||
      !!props?.showLoader
    );
  }, [frameIsSized, imagesLoaded, isNonImage, props?.showLoader]);
  return (
    <div
      ref={containerRef}
      key={props?.nft}
      className={`${
        props?.useFrameContainer2 && !(frameRatio < 1)
          ? "nft-frame-container2"
          : "nft-frame-container"
      } ${!frameURL ? "no-frame-container" : ""} ${
        !frameURL && isNonImage() ? "nonimage-max-size" : ""
      } ${
        (!frameIsSized ||
          imagesLoaded.image ||
          (imagesLoaded.frame && frameURL)) &&
        !isNonImage() &&
        "skelton-loading"
      } nft-frame-container`}
    >
      <div
        data-index={`${props["data-index"]}`}
        onClick={onClickHandler}
        style={{
          height: props?.frameDetailsStyles ? "" : frameHeight,
          width: props?.useFrameContainer2 ? "100%" : frameWidth,
          margin: "auto",
          aspectRatio:
            props.frameDetails?.metaInfo?.frame?.aspectRatio?.replace(
              ":",
              "/"
            ) ?? `${imageRatio} / 1`,
          "--border-aspect-ratio": imageRatio < 1 ? imageRatio : 1,
        }}
        className={`nft-frame-viewer ${getClassStyles()} ${getClassNames()} 
          skelton-loaded ${props?.className}
        `}
      >
        <ImageWithLoader loader={props?.showLoader} image={props?.nftSrc} />
      </div>
    </div>
  );
};

export default memo(ImageCard);
