/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";

import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";

import CollectionListing from "../../Components/Table";
import { LIST_LIMIT } from "App/constants";
import { getBlockchainIcon } from "App/helpers/blockchain";

const paginationConfig = {
  perPage: LIST_LIMIT,
};
const CollectorsCollectionsList = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [filteredData, setFilteredData] = useState("");
  const [initial, setInitial] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const [showActivatePop, setShowActivatePop] = useState({ mode: false });

  const [walletAddress, setWalletAddress] = useState('');
  const [blockchain, setBlockchain] = useState('');
  const [note, setNot] = useState('');
  const [status, setStatus] = useState(['inactive']);

  const [walletCollectionIds, setWalletCollectionIds] = useState([]);



  const [sort, setSort] = useState({ direction: "DESC", sortKey: "createdAt" });

  const [list, setList] = useState([]);
  const [search, setSearch] = useState('');
  const searchHandler = (e) => {
    setSearch(e);
  }

  const getCollectionListResult = useCallback(
    (res) => {
      const { list, pageMeta, note, 
        walletAddress, blockchain,collectionIds,status
       } = res.data;

      let collection = list?.map((ele) => {
        return { ...ele, owners: ele?.ownersCount, 
          items: ele?.assetCount, blockchainIcon: getBlockchainIcon(ele?.blockchainType?.code),note }
      });
      setWalletAddress(walletAddress??"")
      setBlockchain(blockchain??"")
      setNot(note??"")
      setWalletCollectionIds(collectionIds)
      setStatus(status??['inactive'])
      setList(collection);

      setTotalPage(pageMeta.totalItems);
      setTimeout(() => {
        dispatch("hideSpinner");
      }, 300);
    },
    [dispatch]
  );

  const getCollectionList = useCallback((collectorDetails={}) => {
    dispatch("showSpinner");
    const filter = {
      sortBy: sort.sortKey ? sort.sortKey : "DESC",
      orderBy: sort.sortKey ? sort.direction : "createdAt",
      ...filteredData,
    };

    triggerAPI(
      {
        url: `community/collection/get`,
        data: {
          page: page,
          items: paginationConfig.perPage,
          walletAddress:collectorDetails?.walletAddress || walletAddress || null,
          blockchain:collectorDetails?.blockchain || blockchain || null,
          note:collectorDetails?.note || note || null,
          collectionIds:collectorDetails?.walletAddress ? [] : walletCollectionIds || [],
          search: `${search}`,
          filter: { status: collectorDetails.status==='all' ? ['inactive','active','reviewed'] : 
            (collectorDetails?.status ? [collectorDetails.status] : status), ...filter },
        },
        method: "post",
      },
      getCollectionListResult
    );
  }, [
    dispatch,
    filteredData,
    search,
    getCollectionListResult,
    page,
    sort.direction,
    sort.sortKey,
    triggerAPI,
  ]
);


  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    dispatch("showToast", { toast: { toastMode: "success", message } });
    if (showActivatePop?.mode) setShowActivatePop({ mode: false });
    getCollectionList();
  };

  const activateHandler = (action) => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `community/collection/update-status`,
        data: {
          id: selectedData?.id,
          status: `${action}`,
        },
        method: "patch",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message ||
              `${action === 'reviewed' ? 'Reviewing' : 'Blocking'
              } community failed`,
          },
        });
      }
    );
  };

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getCollectionList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, filteredData, search]);

  useEffect(() => {
    if (!initial) {
      getCollectionList();
    }
    setInitial(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch("setPageTitle", "Collection Management");
  }, [dispatch]);

  const downloadExcelFile = async (res) => {
    dispatch("hideSpinner");
    const blob = new Blob([res], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });    
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'Collector_collections.xlsx');  
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const onExport = () => {
    if(!walletCollectionIds || !walletCollectionIds.length ){
      dispatch("showToast", {
        toast: {
          toastMode: "error",
          message: "Please do search",
        },
      });
    }else{
      
      dispatch("showSpinner");
      triggerAPI(
        {
          url: `admin/export-collection-details`,
          method: "post",
          data: {
            collectionIds:walletCollectionIds,
          },
          responseType:'blob',
          headers: {
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          },
        },
        downloadExcelFile,
        (err) => {
          dispatch("hideSpinner");
          dispatch("showToast", {
            toast: {
              toastMode: "error",
              message:
                err?.response?.data?.error?.message || "Error downloading the file",
            },
          });
        }
      );
    }
      

  };

  return (
    <CollectionListing
      sort={sort}
      selectedData={selectedData}
      showActivatePop={showActivatePop}
      list={list}
      page={page}
      totalPage={totalPage}
      paginationConfig={paginationConfig}
      setPage={setPage}
      setSelectedData={setSelectedData}
      setFilteredData={setFilteredData}
      setShowActivatePop={setShowActivatePop}
      filteredData={filteredData}
      setSort={setSort}
      activateHandler={activateHandler}
      onSearchChange={searchHandler}
      onRefetchCollectionList={getCollectionList}
      type = "collectorCollection"
      getCollection={getCollectionList}
      collectorsCollectionsExport={onExport}
    />
  );
};

export default CollectorsCollectionsList;
