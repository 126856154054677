import { useState, useCallback, useEffect } from 'react';
import useEventListener from './use-event-listener';

const  useInfiniteScroll = (callback, options = { elementId: null, scrollRef: null }) => {
  const [isFetching, setIsFetching] = useState(false);

  const handleScroll = useCallback(() => {
    let scrollElement;
    if (options?.elementId) {
      scrollElement = document.getElementById(options.elementId);
    } else if (options?.scrollRef && options?.scrollRef.current) {
      scrollElement = options.scrollRef.current;
    } else {
      scrollElement = window;
    }
    const scrollTop = scrollElement === window ? window.scrollY : scrollElement.scrollTop;
    const clientHeight = scrollElement === window ? window.innerHeight : scrollElement.clientHeight;
    const scrollHeight = scrollElement === window ? document.documentElement.scrollHeight : scrollElement.scrollHeight;
    if (scrollTop + clientHeight >= (scrollHeight - 500) && !isFetching) {
      setIsFetching(true);
      callback();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, options]);

  useEventListener('scroll', handleScroll, options?.elementId ? document.getElementById(options.elementId) : options?.scrollRef?.current || window);

  useEffect(() => {
    let scrollElement;
    if (options?.elementId) {
      scrollElement = document.getElementById(options?.elementId);
    } else if (options?.scrollRef && options?.scrollRef.current) {
      scrollElement = options?.scrollRef.current;
    } else {
      scrollElement = document.documentElement;
    }

    if (scrollElement?.scrollHeight <= scrollElement?.clientHeight) {
      setIsFetching(true);
      callback();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  

  return [isFetching, setIsFetching];
}

export default useInfiniteScroll;
