export  const collectionsWithFrameAdded = ({ collections = [], mediaFiles = [], userAddedFrameList = [] }) => {
    const addFrameToCollection = (items) => items.map(item => {
        let frame = userAddedFrameList?.find(({ asset }) => asset === item?.asset) ?? null;
        if (frame) {
            frame = {
                isLandscape: frame?.isLandscape,
                ...frame,
                ...frame?.frame
            };
            delete frame?.frame;
        }
        return { ...item, frame, frameId: frame?.frameId ?? null };
    });

    return {
        collections: addFrameToCollection(collections),
        mediaFiles: addFrameToCollection(mediaFiles)
    };
}