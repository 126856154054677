import { useState, useCallback, useMemo } from 'react';
import useHttp from './use-http';

const useMarketplace = () => {
  const [marketplaceAssetIds, setMarketplaceAssetIds] = useState([]);
  const [marketplaceAssetIdsFetching, setMarketplaceAssetIdsFetching] = useState(true);
  const { triggerAPI } = useHttp();
  const getMartketplaceData = useCallback(async (collectionObj = {}, includeNonSaleAssets = true, limit=200, setIDs = true, order = "price_asc",hideAssets=false) => {
   
    setMarketplaceAssetIdsFetching(true)
    let collectionData = {...collectionObj};
    let blockChain = collectionData?.onchain_metadata?.chain ?? collectionData?.blockchainType?.code ?? 'cardano';
    if(blockChain !== 'cardano' && collectionData?.assets?.length) {
      collectionData = {...collectionData, assets: collectionData?.assets?.filter(asset => asset?.quantity)}
    }
    try {
      let marketplaceAssets = [];
      if (blockChain === 'cardano') {
        await triggerAPI({
          url: `marketplace/cardanoListings`,            
          method: "POST",
          data: {
            policyId: collectionData.collectionId,
            limit: limit
          }
        }, (res) => {            
          marketplaceAssets = 
            res.data                     
            .map(n=> {
              return {
                price: n.price,                
                nft_id: `.${collectionData.collectionId}.${n.asset_name}`,
                permalink: n.permalink,
                chain: "cardano",
                policy_id: collectionData.collectionId,
                contractAddress: collectionData.collectionId,
                asset_name: n.asset_name,
                name: n.name,
                isHidedAsset:n?.isHidedAsset
              }
            });                

          marketplaceAssets = marketplaceAssets.sort((a,b)=>parseInt(a.price??9999999999)>parseInt(b.price??9999999999)?1:parseInt(a.price??9999999999)<parseInt(b.price??9999999999)?-1:a.asset_name>b.asset_name?1:-1);         
        },()=>{});                
      }
      else if (blockChain === 'tezos')
      {
        if (collectionData.contractAddress)
        {
          await triggerAPI({
            url: `marketplace/tezosListings`,            
            method: "POST",
            data: {
              contractAddress: collectionData.contractAddress,
              limit: limit,
              order_by: order
            }
          }, (res) => {            
            marketplaceAssets = 
              res.data                     
              .map(n=> {
                return {
                  price: n.price_xtz,
                  priceDisplay: n.price_xtz / Math.pow(10,6) + ' ꜩ',
                  nft_id: `tezos.${collectionData.contractAddress}.${n.token.token_id}`,
                  permalink: n.permalink,
                  isHidedAsset:n?.isHidedAsset,
                }
              });               

            marketplaceAssets = [...marketplaceAssets.map(d=> {
              let nftid = d.nft_id.split(".");
              return {...d, chain: nftid[0], policy_id: nftid[1], contractAddress: nftid[1], asset_name: nftid[2]??'0', priceDisplay: d.priceDisplay}
            })]              
          },(err)=>{});
        }
      }
      else 
      {
        const params = new URLSearchParams({
          collectionId: collectionData.collectionId,          
          order: order
        });  

        await triggerAPI({
          url: `community/collection/get-marketplace?${params}`,            
          method: "get",
        }, (res) => {    
          marketplaceAssets = [...new Set(
            res.data
            .filter(a => !a.is_private)
            .map(a => a.nft_id))] //get nft_id, convert to set to remove duplicates
            .map(a=> {
              let match = res.data.filter(d=>d.nft_id === a).sort((a,b)=>a.price>b.price?1:-1)[0];
              return {...match, nft_id: match.nft_id}
            }); //map into correct assetids format for core code

          marketplaceAssets = [...marketplaceAssets.map(d=> {
            let nftid = d.nft_id.split(".");
            return {...d, chain: nftid[0], policy_id: nftid[1], contractAddress: nftid[1], asset_name: nftid[2]??'0', priceDisplay: d.price / Math.pow(10,d.payment_token.decimals) + ' ' + d.payment_token.symbol}
          })]  
        },()=>{});
        ///
      }
      marketplaceAssets = hideAssets ? marketplaceAssets?.filter(list=>!list?.isHidedAsset) : marketplaceAssets;

      if (limit)
      {
        marketplaceAssets = marketplaceAssets.slice(0, limit);
      }
      if (includeNonSaleAssets && marketplaceAssets.length < 30) {
        if (collectionData?.assets?.length) {
          //we already have collection assets, so just use those to fill the remainder        
          marketplaceAssets = [...marketplaceAssets, ...collectionData.assets.filter(a=> a.asset_name && marketplaceAssets.findIndex(ma=>ma.asset_name === a.asset_name && ma.policy_id === a.policy_id)===-1).slice(0, 30-marketplaceAssets.length).map(a => { return { asset_name: a.asset_name,isHidedAsset:a?.isHidedAsset, policy_id: a.policy_id??collectionData?.collectionId, chain: a?.chain ?? collectionData?.blockchainType?.code, contractAddress: a.policy_id??collectionData?.collectionId}})]                
        }      
        else {
          //sometimes we don't have collection assets, so get them from the db
          if(collectionData.id){
          const params = new URLSearchParams({
            id: collectionData.id
          });  
          await triggerAPI({
              url: `community/collection/get-one?${params}`,            
              method: "get",
            }, (res) => {  
              let filteredLists= hideAssets ? res.data.assets?.filter(list=>!list?.isHidedAsset) : res.data.assets;  
              marketplaceAssets = [...marketplaceAssets, ...filteredLists?.filter(a=> a.asset_name && marketplaceAssets.findIndex(ma=>ma.asset_name === a.asset_name)===-1).slice(0, 30-marketplaceAssets.length).map(a => { return { asset_name: a.asset_name,isHidedAsset:a?.isHidedAsset, policy_id: a.policy_id??collectionData?.collectionId, chain: a?.chain ?? collectionData?.blockchainType?.code, contractAddress: a.policy_id??collectionData?.collectionId}})]              
            });
          }
        }
      }
      if (setIDs)
      {
        setMarketplaceAssetIds(marketplaceAssets);     
        setMarketplaceAssetIdsFetching(false);  
      }
      else
      {
        return marketplaceAssets;
      }
    } catch (error) {
      console.log('error market place', error);
    }
      
    // eslint-disable-next-line react-hooks/exhaustive-deps       
  }, [])

  const getMartketplaceDataFromList = useCallback(async (collectionList) => {
    setMarketplaceAssetIdsFetching(true)
    let assetIds = [];
    for (let i = 0; i < collectionList?.length; i++) {      
      let collection = collectionList[i];            
      if (collection?.blockchainType?.code !== "cardano")
      {      
        let ids = await getMartketplaceData(collection, false, 5, false, "listing_timestamp_desc");        
        if (ids?.length)
        {
          assetIds.push(...ids);        
          if (assetIds?.length >= 10)
          {
            assetIds = assetIds?.slice(0,10);
            break;
          }
        }      
      }
    }    
    assetIds = assetIds?.sort((a,b)=>new Date(b?.listing_timestamp)-new Date(a?.listing_timestamp));    
    setMarketplaceAssetIds(assetIds);
    setMarketplaceAssetIdsFetching(false);     
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const updateMarketplaceKey = (data, assetIds) => {
    if (!assetIds?.length && (assetIds[0]?.price !== null || assetIds[0]?.priceUSD !== null)) {
        return data; // Return the original data if the condition is not met
    }

    return assetIds.map(a=> {
      const match = data.find(item=> {
        if (a.nft_id) {
          const [part1, part2, part3] = a.nft_id.split(".");
          const nftcombinedid = `${part1}${part2}${part3 || a.asset_name}`;
          return item.asset?.toLowerCase() === nftcombinedid.toLowerCase();          
        }
        return a.asset_name?.toLowerCase() === item.asset_name?.toLowerCase();
      })

      if (match) {
        return { ...match, marketplace: a }; // Return a new object with the marketplace updated
      }

      return a; // Return the original item if no match is found
    })
  };
  const getMarketplaceList = useCallback((list) => {
        return list?.filter(item => (item?.marketplace?.price || item?.marketplace?.priceDisplay) && item.marketplace?.state !== 'sold');     
}, [])

  const memoedValue = useMemo(() => {
   return { marketplaceAssetIds, getMartketplaceData, getMartketplaceDataFromList, marketplaceAssetIdsFetching, updateMarketplaceKey, getMarketplaceList }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [marketplaceAssetIds, marketplaceAssetIdsFetching])
  return memoedValue;
}

export default useMarketplace;