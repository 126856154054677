import { useEffect, useState } from "react";

const useDragAndDrop = (
  onDrop,
  currentSelectedList,
  idKey = "asset",
) => {
  const [draggedItemId, setDraggedItemId] = useState(null);
  const [draggedOverContainerId, setDraggedOverContainerId] = useState(null);

  const handleDragStart = (id) => setDraggedItemId(id);
  const handleDragEntered = (id) => {
    if(currentSelectedList?.length>1){
        setDraggedOverContainerId(id)
    }
    
}

  const handleDrop = () => {

    if (!draggedOverContainerId) {
     // clearState();
      return;
    }
    const fromIndex = currentSelectedList.findIndex((list) => list[idKey] === draggedItemId);
    const toIndex = currentSelectedList.findIndex((list) => list[idKey] === draggedOverContainerId);
    const draggedData = currentSelectedList.find((list) => list[idKey] === draggedItemId)
    if(onDrop){
      onDrop(fromIndex,toIndex,draggedData)
    }
    clearState();
  };

  const clearState = () => {
    setDraggedItemId(null);
    setDraggedOverContainerId(null);
  };

  useEffect(()=>{
    if(!draggedOverContainerId&&draggedItemId&&currentSelectedList?.length>1){
        setDraggedOverContainerId(draggedItemId)
    }
  },[draggedItemId,draggedOverContainerId,currentSelectedList])
  const handleDragLeave = () => setDraggedOverContainerId(null);
  return {handleDragLeave,handleDrop,handleDragEntered,handleDragStart,draggedOverContainerId}
};

export default useDragAndDrop;
