import "../Icons.scss";

const BoxEyeVisibleIcon = ({ className, dataIndex, dataPolicy, onClick = () => { } }) => {
    return (
        <div
            className={`icon-wrapper cursor-pointer stroke-color  ${className}`}
            data-index={dataIndex}
            data-policy={dataPolicy}
            onClick={onClick}
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#191D23" />
                <path d="M5 12.3333C5 12.3333 7.66667 7 12.3333 7C17 7 19.6666 12.3333 19.6666 12.3333C19.6666 12.3333 17 17.6666 12.3333 17.6666C7.66667 17.6666 5 12.3333 5 12.3333Z" stroke="#A0ABBB" />
                <path d="M12.332 14.3333C13.4366 14.3333 14.332 13.4378 14.332 12.3333C14.332 11.2287 13.4366 10.3333 12.332 10.3333C11.2275 10.3333 10.332 11.2287 10.332 12.3333C10.332 13.4378 11.2275 14.3333 12.332 14.3333Z" stroke="#A0ABBB" />
            </svg>
        </div>
    );
};

export default BoxEyeVisibleIcon;
