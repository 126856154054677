import "../Icons.scss";

const GamingIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M8.0026 7.0026L6.0026 5.0026V1.33594H10.0026V5.0026L8.0026 7.0026ZM11.0026 10.0026L9.0026 8.0026L11.0026 6.0026H14.6693V10.0026H11.0026ZM1.33594 10.0026V6.0026H5.0026L7.0026 8.0026L5.0026 10.0026H1.33594ZM6.0026 14.6693V11.0026L8.0026 9.0026L10.0026 11.0026V14.6693H6.0026ZM8.0026 5.1026L8.66927 4.43594V2.66927H7.33594V4.43594L8.0026 5.1026ZM2.66927 8.66927H4.43594L5.1026 8.0026L4.43594 7.33594H2.66927V8.66927ZM7.33594 13.3359H8.66927V11.5693L8.0026 10.9026L7.33594 11.5693V13.3359ZM11.5693 8.66927H13.3359V7.33594H11.5693L10.9026 8.0026L11.5693 8.66927Z"
          fill="#F7F8F9"
        />
      </svg>
    </div>
  );
};

export default GamingIcon;
