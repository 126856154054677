import Search from "Assets/Images/Search.svg";
import { useRef } from "react";

const SearchBar = (props) => {
  const timeOut = useRef(null);
  const inputOnchange = (e) => {
    if (timeOut.current) {
      clearTimeout(timeOut.current);
    }
    timeOut.current = setTimeout(() => {
      props.onSearch(e.target.value);
    }, 1000);
  };
  return (
    <div className="search search-input flex items-center">
      <div className="search-input-group mr-3">
        <input
          className="search-input-box"
          type="text"
          placeholder={props?.placeholder||"Search"}
          onKeyUp={inputOnchange}
        />
      </div>
      <div className="search-icons flex items-center">
        <img src={Search} alt="ico" width="18.5px" height="18.5px" />
      </div>
    </div>
  );
};

export default SearchBar;
