import { splitWord } from "App/helpers/utilities";
import Logo from "Assets/Icons/logo-square.svg";
import "./SmallInfoSection.scss";

const SmallInfoSection = ({ showData, data }) => {
  return (
    <div className="flex gap-[.75rem] w-full small-info-section">
      {!!(showData?.communityImage)&&(
        <img className="img-profile"
          src={data?.communityDisplayPicture256 || Logo()}
          alt="icon"
        />
      )}
      <div
        className={`${
          ((showData?.collectionName && data?.collectionName) ||
            (showData?.communityName && data?.communityName)) &&
          "mt-[-0.25rem]"
        } w-full flex flex-col items-start justify-start gap-[0.25rem]`}
      >
        {!!(showData?.collectionName && data?.collectionName) && (
          <div className="collection-name ">{data?.collectionName}</div>
        )}
        {!!(showData?.communityName) && (
          <div className="community-name ">{data?.communityName||"Unknown"}</div>
        )}
        {!!showData?.assetName && (
          <div className={`asset-name flex`}>{data?.name}</div>
        )}
        {!!showData?.tokenId && (
          <div className={`asset-name flex items-center`}><span className="short-heading">{`ID: `}</span>{splitWord(data?.asset_name,8)}</div>
        )}
         {!!showData?.contractAddress && (
          <div className={`asset-name flex items-center`}><span className="short-heading">{`Contract Address: `}</span>{splitWord(data?.contractAddress,6)}</div>
        )}
      </div>
    </div>
  );
};

export default SmallInfoSection;
