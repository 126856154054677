export const thousandCommaSeparator =(amount)=>{
    return amount ? amount?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0; 
 }

 export const formatNumberWithThousand =(numberValue = 0, maxDecimalLength = 6)=>{
    let num = Number(numberValue);
    if (num >= 1) {
        num = parseFloat(num.toFixed(1)); // For numbers >= 1, round to 1 decimal place
      } else {
        // For numbers < 1, preserve up to maxDecimalLength decimal places, restricting beyond maxDecimalLength
        const decimalPart = num?.toString()?.split('.')?.[1];
        num = decimalPart && decimalPart.length > maxDecimalLength ? parseFloat(num.toFixed(maxDecimalLength)) : num;
      }
    if (num >= 1000 && num < 1000000) {
        return thousandCommaSeparator((num / 1000)?.toFixed(1)?.replace(/\.0$/, '')) + 'K';
    } if (num >= 1000000 && num < 1000000000) {
        return thousandCommaSeparator((num / 1000000)?.toFixed(1)?.replace(/\.0$/, '')) + 'M';
    } if (num >= 1000000000 && num < 1000000000000) {
        return thousandCommaSeparator((num / 1000000000)?.toFixed(1)?.replace(/\.0$/, '')) + 'B';
    } if (num >= 1000000000000 && num < 1000000000000000) {
        return thousandCommaSeparator((num / 1000000000000)?.toFixed(1)?.replace(/\.0$/, '')) + 'T';
    } if (num >= 1000000000000000) {
        return thousandCommaSeparator((num / 1000000000000000)?.toFixed(1)?.replace(/\.0$/, '')) + 'Q';
    }

    return num;
 }