import "../Icons.scss";

const BoxFavoriteIcon = ({ className, dataPolicy, dataIndex, onClick = () => { } }) => {
    return (
        <div
            className={`icon-wrapper cursor-pointer fill-color  ${className}`}
            data-index={dataIndex}
            data-policy={dataPolicy}
            onClick={onClick}
        >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="12" cy="12" r="12" fill="#191D23" />
                <path d="M18.3937 7.71769C18.0222 7.34606 17.5812 7.05125 17.0958 6.85012C16.6104 6.64898 16.0901 6.54546 15.5646 6.54546C15.0392 6.54546 14.5189 6.64898 14.0334 6.85012C13.548 7.05125 13.107 7.34606 12.7355 7.71769L11.9646 8.4886L11.1937 7.71769C10.4434 6.96737 9.42573 6.54584 8.36462 6.54584C7.3035 6.54584 6.28585 6.96737 5.53553 7.71769C4.7852 8.46801 4.36368 9.48567 4.36368 10.5468C4.36368 11.6079 4.7852 12.6256 5.53553 13.3759L6.30643 14.1468L11.9646 19.805L17.6228 14.1468L18.3937 13.3759C18.7653 13.0044 19.0601 12.5634 19.2613 12.078C19.4624 11.5925 19.5659 11.0722 19.5659 10.5468C19.5659 10.0213 19.4624 9.50104 19.2613 9.01562C19.0601 8.53019 18.7653 8.08915 18.3937 7.71769V7.71769Z" stroke="#A0ABBB" strokeLinecap="round" strokeLinejoin="round" />
            </svg>

        </div>
    );
};

export default BoxFavoriteIcon;
