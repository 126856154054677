import "../Icons.scss";

const PlusWithoutBorderIcon = ({
  className,
  onClick = () => {},
}) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer stroke-color ${className}`}
      onClick={onClick}
    >
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.5 5V19"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.5 12H19.5"
          stroke="#A0ABBB"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
};

export default PlusWithoutBorderIcon;
