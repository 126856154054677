import SearchFilter from "App/Components/UI/SearchFilter";
import Table from "App/Components/UI/Table";
import TableHeader from "App/Components/UI/Table/Components/TableHeader";
import ActionButton from "Assets/Images/ActionButton.svg";
import Image from "App/Components/UI/Image";
import { useCallback, useEffect, useState } from "react";
import tableSort from "App/helpers/tableSort";
import ButtonAction from "App/Components/UI/ButtonAction";
import useHttp from "App/hooks/use-http";
import { useStore } from "App/hooks-store/store";
import Pagination from "App/Components/UI/Pagination";
import Status from "App/Components/UI/Status";
import MenuPen from "Assets/Images/menu-pen.svg";
import MenuLink from "Assets/Images/menu-link.svg";
import "../../../../Pages/Communities/Pages/CommunityList/CommunitiesList.scss";
import SocialMedia from "App/Components/UI/SocialMedia";
import ConfirmPopup from "App/Components/UI/ConfirmPopup";
import { LIST_LIMIT } from "App/constants";
import ModalOuter from "App/Components/UI/ModalOuter";
import EditCommunity from "../../../Communities/Pages/EditCommunity";
import { getBlockchainIcon } from "App/helpers/blockchain";
import ToggleSwitch from "App/Components/UI/ToggleSwitch";

const actionBlockMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Deactivate",
    action: "block",
    src: MenuLink,
  },
];
const actionMenu = [
  {
    label: "Edit",
    action: "edit",
    src: MenuPen,
  },
  {
    label: "Activate",
    action: "unblock",
    src: MenuLink,
  },
];

const paginationConfig = {
  perPage: LIST_LIMIT,
};

const CuratorList = () => {
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const dispatch = useStore(false)[1];
  const { triggerAPI } = useHttp();
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState("");
  const [initial, setInitial] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const [isPopupOpened, setIsPopupOpened] = useState(false);
  const [showActivatePop, setShowActivatePop] = useState({ mode: false });
  const [curatorMenu, setCuratorMenu] = useState([
    {
      label: "Type",
      action: "type",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Collections",
      action: "collectionId",
      sort: true,
      sortKey: "collectionsCount",
      multiSelect: true,
      selected: true,
      type: "displayedFrames",
    },
    {
        label: "Communities",
        action: "communityId",
        sort: true,
        sortKey: "communityCount",
        multiSelect: true,
        selected: true,
        type: "displayedFrames",
      },
    // {
    //   label: "Posts",
    //   action: "announcements",
    //   selected: true,
    //   multiSelect: true,
    //   type: "displayedFrames",
    // },
    // {
    //   label: "Upcoming Events",
    //   action: "events",
    //   selected: true,
    //   multiSelect: true,
    //   type: "displayedFrames",
    // },
    {
      label: "About Us",
      action: "aboutus",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Social Links",
      action: "sociallink",
      selected: false,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Blockchains",
      action: "blockchains",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    // {
    //   label: "Blockchain IDs",
    //   action: "blockchainId",
    //   selected: false,
    //   multiSelect: true,
    //   type: "displayedFrames",
    // },
    {
      label: "Show on Home",
      action: "statusToggle",
      sortKey: "showOnHomePageDisplay",
      selected: true,
      sort: true,
      multiSelect: true,
      type: "statusToggle",
    },
    {
      label: "Created At",
      action: "createdAt",
      sort: true,
      sortKey: "createdAt",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
    {
      label: "Created By",
      action: "createdBy",
      selected: true,
      multiSelect: true,
      type: "displayedFrames",
    },
  ]);

  const [sort, setSort] = useState({ direction: "ASC", sortKey: "createdAt" });
  const onSortHandler = useCallback(
    (sortKey) => {
      const dir = sort.sortKey === sortKey ? sort.direction : "ASC";
      const direction = tableSort(dir||"ASC");
      setSort({ direction, sortKey });
    },
    [sort]
  );
  const [list, setList] = useState([]);

  const onPageChangeHandler = (page) => {
    setPage(page);
            const root = document.getElementById('content');
        root.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
  };
  const actionHandler = (e) => {
    setSelectedData(e.row);
    switch (e?.action) {
      case "edit":
        setIsPopupOpened(true)
      // navigate(`details/${e?.row?.id}`);
      return
      case "block":
      case "unblock":
        return setShowActivatePop({ mode: true });
      default:
        return;
    }
  };
  const stopHandler = (e) => {
    e.stopPropagation();
  };

  const searchHandler = (e) => {
    setSearch(e);
  };

  const getCuratorListResult = useCallback(
    (res) => {
      dispatch("hideSpinner");
      const { list, pageMeta } = res.data;
      setList(list);
      setTotalPage(pageMeta.totalItems);
    },
    [dispatch]
  );

  const getCuratorList = useCallback(() => {
    dispatch("showSpinner");
    const payload =search?`&search=${search}`:""
    triggerAPI(
      {
        url: `admin/all-curators?page=${page}&limit=${paginationConfig.perPage}${payload}&&sortBy=${sort.sortKey}&orderBy=${sort.direction||"ASC"}`,
        method: "get",
      },
      getCuratorListResult
    );
  }, [
    dispatch,
    getCuratorListResult,
    page,
    search,
    sort.direction,
    sort.sortKey,
    triggerAPI,
  ]);

  const apiResult = (res) => {
    const { message } = res;
    dispatch("hideSpinner");
    dispatch("showToast", { toast: { toastMode: "success", message } });
    if (showActivatePop?.mode) setShowActivatePop({ mode: false });
    getCuratorList();
  };

  const getToggleStatusUpdateResult = (res) => {
    getCuratorList();
    const { message } = res;
    dispatch('hideSpinner')
    dispatch('showToast', { toast: { toastMode: 'success', message } })
  }

  const getToggleStatusUpdate = (id, checked) => {
      dispatch('showSpinner');
      const data = {
          id: id,
          showOnHomePageDisplay: checked
      }
      triggerAPI({
          url: `curator/curator-display-status/update`, data, method: 'patch'
      }, getToggleStatusUpdateResult);
  }
  const toggleSwitch = (id, checked) => {
    getToggleStatusUpdate(id, checked);
  }


  const activateHandler = () => {
    dispatch("showSpinner");
    triggerAPI(
      {
        url: `curator/${
          selectedData?.status === "true" || selectedData?.status === "active"
            ? "block"
            : "active"
        }/${selectedData?.id}`,
        data: {},
        method: "patch",
      },
      apiResult,
      (err) => {
        dispatch("hideSpinner");
        dispatch("showToast", {
          toast: {
            toastMode: "error",
            message:
              err?.response?.data?.error?.message ||
              `${
                selectedData?.isBlocked ? "Blocking" : "Unblocking"
              } community failed`,
          },
        });
      }
    );
  };

  const onFilterData = (e) => {
    setFilteredData(e);
  };

  useEffect(() => {
    if (page !== 1) {
      setPage(1);
    } else {
      getCuratorList();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort, search, filteredData]);

  useEffect(() => {
    if (!initial) {
        getCuratorList();
    }
    setInitial(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    dispatch("setPageTitle", "Curator Management");
  }, [dispatch]);

  const onCloseActivate = (data) => {
    if (data) {
      activateHandler();
    } else {
      setShowActivatePop({ mode: false });
    }
  };




  return (
    <>
    {!!isPopupOpened && <ModalOuter openModal={true}
        parentClass={isPopupOpened ? '!z-[10]' : '!hidden !z-[10]'}
        >
        <EditCommunity onClosePopup={setIsPopupOpened} 
          type="curator"
          id={selectedData?.id} onRefetchList={getCuratorList}
        />
      </ModalOuter>}
      <div className="communities h-full">
        <div className="flex justify-between items-center mb-4">
          <div className="search-filter">
            <SearchFilter
              position={{ right: 150, top: 10 }}
              onSearch={searchHandler}
              data={filteredData}
              setActionMenu={setCuratorMenu}
              actionMenu={curatorMenu}
              onFilterData={onFilterData}
              apply={true}
              limit={6}
            />
          </div>
        </div>
        <div className="min-h-[90%] flex flex-col justify-between">
          <Table>
            <thead className="text-left">
              <tr>
                <th>
                  <TableHeader
                    label="Curator"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="name"
                  />
                </th>

                {curatorMenu
                  ?.filter((el) => el?.selected)
                  ?.map((val) => (
                    <th key={val?.action}>
                      <TableHeader
                        label={val?.label}
                        sort={sort}
                        onSort={(key) => val?.sort && onSortHandler(key)}
                        sortKey={val?.sortKey ? val?.sortKey : ""}
                      />
                    </th>
                  ))}
                <th>
                  <TableHeader
                    label="Status"
                    sort={sort}
                    onSort={onSortHandler}
                    sortKey="status"
                  />
                </th>
                <th>
                  <div className="flex justify-end items-center">
                    <TableHeader label="Actions" />
                  </div>
                </th>
              </tr>
            </thead>
            <tbody className="gallery-body">
              {list?.map((value) => {
                let imagePreview = value?.displayPicture;
                return (
                  <tr data-galleryid={value?.id} key={value.id}>
                    <td className="flex gap-3 items-center">
                      <div className="wall-image-gallery">
                        <Image
                          src={imagePreview ? imagePreview : ""}
                          className="gallery-image"
                          loaderClass="nft-drop-loader"
                          style={{ maxWidth: "4rem", Height: "4rem" }}
                        />
                      </div>
                      <span className="name-span">{value?.name}</span>
                    </td>
                    {!!curatorMenu?.find(
                      (val) => val?.label === "Type"
                    )?.selected && <td>{value?.user?.curatorCategory?.name}</td>}
                    {!!curatorMenu?.find(
                      (val) => val?.label === "Collections"
                    )?.selected && <td>{value?.collectionsCount || value?.communityCollections?.length}</td>}
                    {!!curatorMenu?.find(
                      (val) => val?.label === "Communities"
                    )?.selected && <td>{value?.communityCount || value?.curatorCommunity?.length}</td>}
                    {/* {!!curatorMenu?.find(
                      (val) => val?.label === "Posts"
                    )?.selected && <td>{value?.announcementsCount}</td>}
                    {!!curatorMenu?.find((val) => val?.label === "Upcoming Events")
                      ?.selected && <td>{value?.upcomingEventsCount}</td>}                     */}
                    {!!curatorMenu?.find((val) => val?.label === "About Us")
                      ?.selected && <td>{value?.about}</td>}
                    {!!curatorMenu?.find(
                      (val) => val?.label === "Social Links"
                    )?.selected && (
                      <td>
                        <div className="flex gap-2">
                          {!!value?.website && (
                            <SocialMedia link={value?.website} type="website" />
                          )}
                          {!!value?.twitter && (
                            <SocialMedia link={value?.twitter} type="twitter" />
                          )}
                          {!!value?.discord && (
                            <SocialMedia link={value?.discord} type="discord" />
                          )}
                          {!!value?.instagram && (
                            <SocialMedia
                              link={value?.instagram}
                              type="instagram"
                            />
                          )}
                        </div>
                      </td>
                    )}
                    {!!curatorMenu?.find((val) => val?.label === "Blockchains")
                      ?.selected && (
                      <td>
                        
                        <div className="flex w-max">
                        {value?.blockchains?.map((code,i)=><img key={i+1} src={getBlockchainIcon(code)} className="w-[1.4rem]" alt=""/>)}
                        </div>
                        </td>
                    )}
                    {/* {!!curatorMenu?.find(
                      (val) => val?.label === "Blockchain IDs"
                    )?.selected && (
                      <td>{value?.fungibleTokens[0]?.blockchainType?.id}</td>
                    )} */}
                    {!!curatorMenu?.find(
                      (val) => val?.label === "Show on Home"
                    )?.selected && <td>
                        <div className="flex gallery-home justify-center items-center" onClick={stopHandler}>
                            {
                            // value?.status === 'active' && 
                            <ToggleSwitch className="gallery-switch" check={value?.showOnHomePageDisplay} row={value} onChange={toggleSwitch} />}
                        </div>
                      </td>}
                    {!!curatorMenu?.find(
                      (val) => val?.label === "Created At"
                    )?.selected && (
                      <td>{new Date(value?.createdAt)?.toLocaleString()}</td>
                    )}
                    {!!curatorMenu?.find(
                      (val) => val?.label === "Created By"
                    )?.selected && (
                      <td>{value?.user?.username}</td>
                    )}
                    <td>
                      <Status
                        label={
                          value?.status === "false" ||
                          value?.status === "in-active"
                            ? "Inactive"
                            : "Active"
                        }
                      />
                    </td>
                    <td>
                      <div
                        className="flex justify-end items-center"
                        onClick={stopHandler}
                      >
                        <ButtonAction
                          row={value}
                          src={ActionButton}
                          menu={
                            value?.status === "false" ||
                            value?.status === "in-active"
                              ? actionMenu
                              : actionBlockMenu
                          }
                          onAction={actionHandler}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
          {!list?.length && (
            <div className="text-center no-rows pt-4">
              <p>No curators found!</p>
            </div>
          )}
          <div className="mt-6">
            <Pagination
              itemsPerPage={paginationConfig.perPage}
              total={totalPage}
              rows={list?.length}
              onPageChange={onPageChangeHandler}
              selected={page}
            />
          </div>
        </div>
      </div>
      <ConfirmPopup
        row={selectedData}
        show={showActivatePop}
        content={`Are you sure want to ${
          selectedData?.status === "false" || selectedData?.status === "in-active"
            ? "activate"
            : "deactivate"
        } this curator?`}
        buttonYes="Yes"
        buttonCancel="No"
        style={{ maxWidth: "30rem" }}
        title={`${
          selectedData?.status === "false" || selectedData?.status === "in-active"
            ? "Activate"
            : "Deactivate"
        } curator`}
        cssClass="delete-user"
        onClose={onCloseActivate}
      ></ConfirmPopup>
    </>
  );
};

export default CuratorList;
