import "../Icons.scss";

const MembershipIcon = ({ className, dataIndex, onClick = () => {} }) => {
  return (
    <div
      className={`icon-wrapper cursor-pointer fill-color ${className}`}
      data-index={dataIndex}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M13.9996 11.0716V12.6633C13.9999 12.8822 13.9571 13.099 13.8736 13.3013C13.79 13.5036 13.6674 13.6875 13.5127 13.8423C13.358 13.9972 13.1743 14.12 12.9721 14.2038C12.7699 14.2876 12.5531 14.3307 12.3342 14.3306H3.66491C3.44605 14.3306 3.22933 14.2875 3.02715 14.2036C2.82497 14.1198 2.6413 13.997 2.48663 13.8421C2.33197 13.6873 2.20934 13.5035 2.12577 13.3012C2.0422 13.0989 1.99931 12.8821 1.99958 12.6633V11.0716L0.70491 5.32827C0.47991 4.1536 1.44424 3.55727 2.38291 4.25827L4.84391 6.0976C4.96458 6.1876 5.06091 6.16494 5.12724 6.03327L6.94591 2.40894C7.44458 1.41527 8.55491 1.41627 9.05291 2.40894L10.8719 6.03327C10.9386 6.16527 11.0342 6.1876 11.1552 6.0976L13.6162 4.25827C14.5549 3.55694 15.5196 4.15227 15.2942 5.32827L13.9996 11.0716ZM3.19958 10.3306H12.7996L13.8296 5.76327L11.9529 7.1656C11.1686 7.75194 10.1196 7.5066 9.68024 6.63127L7.99958 3.28227L6.31858 6.63127C5.87991 7.5056 4.83058 7.75194 4.04591 7.1656L2.16991 5.7636L3.19958 10.3306ZM3.33291 12.6633C3.33265 12.707 3.34104 12.7504 3.35761 12.7909C3.37417 12.8314 3.39859 12.8683 3.42944 12.8993C3.4603 12.9304 3.49698 12.955 3.5374 12.9718C3.57781 12.9886 3.62114 12.9973 3.66491 12.9973H12.3342C12.378 12.9974 12.4214 12.9888 12.4619 12.972C12.5023 12.9552 12.539 12.9306 12.5699 12.8995C12.6008 12.8685 12.6252 12.8316 12.6417 12.791C12.6583 12.7505 12.6666 12.7071 12.6662 12.6633V11.6639H3.33291V12.6633Z"
          fill="#F7F8F9"
        />
      </svg>
    </div>
  );
};

export default MembershipIcon;
