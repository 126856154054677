import Spinner from "App/Components/UI/Spinner";
import DashboardLayout from "App/Pages/Dashboard/DashboardLayout";
import AccessPlan from "App/Pages/Dashboard/Pages/AccessPlan";
import AnalyticsDashboard from "App/Pages/Dashboard/Pages/AnalyticsDashboard";
import ArtBank from "App/Pages/Dashboard/Pages/ArtBank";
import BackgroundWall from "App/Pages/Dashboard/Pages/BackgroundWall";
import CreateThumbnail from "App/Pages/Dashboard/Pages/BackgroundWall/Pages/CreateThumbnail";
import Thumbnail from "App/Pages/Dashboard/Pages/BackgroundWall/Pages/Thumbnail";
import Wall2D from "App/Pages/Dashboard/Pages/BackgroundWall/Pages/Wall2D";
import Create2D from "App/Pages/Dashboard/Pages/BackgroundWall/Pages/Wall2D/Pages/Create2D";
import FrameManagement from "App/Pages/Dashboard/Pages/FrameManagement";
import FreeFrames from "App/Pages/Dashboard/Pages/FrameManagement/Pages/FreeFrames";
import CreateFreeFrames from "App/Pages/Dashboard/Pages/FrameManagement/Pages/FreeFrames/Components/CreateFreeFrames";
import FreeFramesEdit from "App/Pages/Dashboard/Pages/FrameManagement/Pages/FreeFrames/FreeFramesEdit";
import FreeFramesLayout from "App/Pages/Dashboard/Pages/FrameManagement/Pages/FreeFrames/FreeFramesLayout";
import NFTFrames from "App/Pages/Dashboard/Pages/FrameManagement/Pages/NFTFrames";
import CreateNFTFrames from "App/Pages/Dashboard/Pages/FrameManagement/Pages/NFTFrames/Components/CreateNFTFrames";
import NFTFramesLayout from "App/Pages/Dashboard/Pages/FrameManagement/Pages/NFTFrames/NFTFramesLayout";
import GalleryManagement from "App/Pages/Dashboard/Pages/GalleryManagement";
import Gallery from "App/Pages/Dashboard/Pages/GalleryManagement/Pages/Gallery";
import GalleryDetails from "App/Pages/Dashboard/Pages/GalleryManagement/Pages/Gallery/Components/GalleryDetails";
import NFTDrop from "App/Pages/Dashboard/Pages/GalleryManagement/Pages/NFTDrop";
import NFTDropDetails from "App/Pages/Dashboard/Pages/GalleryManagement/Pages/NFTDrop/Components/NFTDropDetails";
import CategoryDetails from "App/Pages/Dashboard/Pages/InformationDesk/Components/CategoryDetails";
import AddContent from "App/Pages/Dashboard/Pages/InformationDesk/Components/CategoryDetails/AddContent";
import InformationCategory from "App/Pages/Dashboard/Pages/InformationDesk/InformationCategory";
import InformationDeskLayout from "App/Pages/Dashboard/Pages/InformationDesk/Pages";
import Settings from "App/Pages/Dashboard/Pages/Settings";
import Password from "App/Pages/Dashboard/Pages/Settings/Pages/Password";
import Profile from "App/Pages/Dashboard/Pages/Settings/Pages/Profile";
import ProfileEdit from "App/Pages/Dashboard/Pages/Settings/Pages/Profile/ProfileEdit";
import ProfileLayout from "App/Pages/Dashboard/Pages/Settings/Pages/Profile/ProfileLayout";
import TransactionHistory from "App/Pages/Dashboard/Pages/TransactionHistory";
import Users from "App/Pages/Dashboard/Pages/Users";
import UserList from "App/Pages/Dashboard/Pages/Users/Pages/UserList";
import UserTransactionDetails from "App/Pages/Dashboard/Pages/Users/Pages/UserList/Components/UserTransactionDetails";
import ProjectManagement from "App/Pages/Dashboard/Pages/Project";
import AddProject from "App/Pages/Dashboard/Pages/Project/Components/AddProject";
import SubAdminList from "App/Pages/Dashboard/Pages/SubAdminManagement";
import { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Terms from "App/Pages/Dashboard/Pages/InformationDesk/Pages/Terms";
import PolicyPrivacy from "App/Pages/Dashboard/Pages/InformationDesk/Pages/Policy";
import TrailUsers from "App/Pages/Dashboard/Pages/Users/Pages/TrailUsers";
import RoleAndPermissions from "App/Pages/Dashboard/Pages/RoleAndPermissions";
import Communities from "App/Pages/Dashboard/Pages/Communities";
import CommunityList from "App/Pages/Dashboard/Pages/Communities/Pages/CommunityList";
// import EditCommunity from "App/Pages/Dashboard/Pages/Communities/Pages/EditCommunity";
import CollectionManagement from "App/Pages/Dashboard/Pages/CollectionManagement";
import CollectionList from "App/Pages/Dashboard/Pages/CollectionManagement/Pages/Collections";
import NewCollectionList from "App/Pages/Dashboard/Pages/CollectionManagement/Pages/NewCollections";
import CollectorsCollections from "App/Pages/Dashboard/Pages/CollectionManagement/Pages/CollectorsCollections";
import UnreviewedVerified from "App/Pages/Dashboard/Pages/CollectionManagement/Pages/UnreviewedVerified";
import EditCollection from "App/Pages/Dashboard/Pages/CollectionManagement/Pages/EditCollection";
import AccessPlanAndArtBank from "App/Pages/Dashboard/Pages/AccessPlanAndArtBank";
import RegistrationManagement from "App/Pages/Dashboard/RegistrationManagement";
import VerifyCollectionsList from "App/Pages/Dashboard/Pages/CollectionManagement/Pages/VerifyCollections";
import VerifiedCollections from "App/Pages/Dashboard/Pages/CollectionManagement/Pages/VerifiedCollections";
import RejectedCollections from "App/Pages/Dashboard/Pages/CollectionManagement/Pages/RejectedCollections";


import SubscriptionPlan from "App/Pages/Dashboard/Pages/SubscriptionPlan";
import MonetReward from "App/Pages/Dashboard/Pages/MonetReward";
import ArtBankGallery from "App/Pages/Dashboard/Pages/GalleryManagement/Pages/ArtBankGallery";
import CollectorsPlan from "App/Pages/Dashboard/Pages/SubscriptionPlan/CollectorsPlan";
import RegistrationReward from "App/Pages/Dashboard/Pages/MonetReward/Pages/RegistrationRewards";
import FrameProjectsLayout from "App/Pages/Dashboard/Pages/FrameManagement/Pages/FrameProjects/FrameProjectsLayout";
import PremiumFramesLayout from "App/Pages/Dashboard/Pages/FrameManagement/Pages/PremiumFrames/PremiumFramesLayout";
import PremiumFramesEdit from "App/Pages/Dashboard/Pages/FrameManagement/Pages/PremiumFrames/PremiumFramesEdit";
import PremiumFrames from "App/Pages/Dashboard/Pages/FrameManagement/Pages/PremiumFrames";
import CreatePremiumFrames from "App/Pages/Dashboard/Pages/FrameManagement/Pages/PremiumFrames/Components/CreatePremiumFrames";
import Curator from "App/Pages/Dashboard/Pages/CuratorManagement";
import CuratorList from "App/Pages/Dashboard/Pages/CuratorManagement/Pages/CuratorsList";

import Referrals from "App/Pages/Dashboard/Pages/Referrals";
import ReferralsList from "App/Pages/Dashboard/Pages/Referrals/Pages/ReferralList";
import InviteList from "App/Pages/Dashboard/Pages/Referrals/Pages/InviteList";


//lazy loading
/*
const DashboardLayout = lazy(() => import('App/Pages/Dashboard/DashboardLayout'));
const Users = lazy(() => import('App/Pages/Dashboard/Pages/Users'));
const Settings = lazy(() => import('App/Pages/Dashboard/Pages/Settings'));
const AnalyticsDashboard = lazy(() => import('App/Pages/Dashboard/Pages/AnalyticsDashboard'));
const ArtBank = lazy(() => import('App/Pages/Dashboard/Pages/ArtBank'));
const UserTransactionDetails = lazy(() => import('App/Pages/Dashboard/Pages/Users/Pages/UserList/Components/UserTransactionDetails'));
const BackgroundWall = lazy(() => import('App/Pages/Dashboard/Pages/BackgroundWall'));
const Thumbnail = lazy(() => import('App/Pages/Dashboard/Pages/BackgroundWall/Pages/Thumbnail'));
const Wall2D = lazy(() => import('App/Pages/Dashboard/Pages/BackgroundWall/Pages/Wall2D'));
const Wall3D = lazy(() => import('App/Pages/Dashboard/Pages/BackgroundWall/Pages/Wall3D'));
const Create2D = lazy(() => import('App/Pages/Dashboard/Pages/BackgroundWall/Pages/Wall2D/Pages/Create2D'));
const GalleryManagement = lazy(() => import('App/Pages/Dashboard/Pages/GalleryManagement'));
const TransactionHistory = lazy(() => import('App/Pages/Dashboard/Pages/TransactionHistory'));
*/
const Private = () => {
  return (
    <Suspense fallback={<Spinner show={true} />}>
      <Routes>
        <Route path="/dashboard" element={<DashboardLayout />}>
          <Route path="" element={<Navigate to="analytics" replace />} />
          <Route path="analytics" element={<AnalyticsDashboard />} />
          <Route path="artbank" element={<ArtBank />} />
          <Route
            path="user-transactions/:id"
            element={<UserTransactionDetails />}
          />
          <Route path="access-plan" element={<AccessPlan />} />
          <Route path="transaction-history" element={<TransactionHistory />} />
          <Route path="art-bank-management">
            <Route path="" element={<AccessPlanAndArtBank />}>
              <Route path="" element={<Navigate to="art-bank" replace />} />
              <Route path="access-plan" element={<AccessPlan />} />
              <Route path="art-bank" element={<ArtBank />} />
            </Route>
          </Route>
          <Route path="users">
            <Route path="" element={<Users />}>
              <Route path="" element={<Navigate to="list" replace />} />
              <Route path="list" element={<UserList />} />
              <Route path="trial" element={<TrailUsers />} />
              <Route
            path="registration"
            element={<RegistrationManagement />}
          />
          
              {/* <Route path="policies" element={<Policies />} /> */}
            </Route>
            {/* <Route path="policies/:id" element={<Policy />} /> */}
          </Route>

          <Route path="referral">
            <Route path="" element={<Referrals />}>
              <Route path="" element={<Navigate to="list" replace />} />
              <Route path="list" element={<ReferralsList />} />
              <Route path="invite" element={<InviteList />} />
              <Route
            path="registration"
            element={<RegistrationManagement />}
          />
          
              {/* <Route path="policies" element={<Policies />} /> */}
            </Route>
            {/* <Route path="policies/:id" element={<Policy />} /> */}
          </Route>

          <Route path="sub-admin">
            <Route path="" element={<Navigate to="list" replace />} />
            <Route path="list" element={<SubAdminList />} />
          </Route>
          <Route path="role-and-permissions" element={<RoleAndPermissions />} />
          <Route path="community-management">
            <Route path="" element={<Communities />}>
              <Route path="" element={<Navigate to="list" replace />} />
              <Route path="list" element={<CommunityList />} />
              {/* <Route path="list/details/:id" element={<EditCommunity />} /> */}
            </Route>
          </Route>
          <Route path="curator-management">
            <Route path="" element={<Curator />}>
              <Route path="" element={<Navigate to="list" replace />} />
              <Route path="list" element={<CuratorList />} />
            </Route>
          </Route>
          <Route path="collection-management">
            <Route path="" element={<CollectionManagement />}>
              <Route path="" element={<Navigate to="collections" replace />} />
              <Route path="collections" element={<CollectionList />} />
              <Route path="new-collections" element={<NewCollectionList />} />
              <Route path="verify-collections" element={<VerifyCollectionsList />} />
              <Route path="verified-collections" element={<VerifiedCollections />} />
              <Route path="rejected-collections" element={<RejectedCollections />} />
              <Route path="collectors-collections" element={<CollectorsCollections />} />
              <Route path="unreviewed-verified" element={<UnreviewedVerified />} />
            </Route>

            <Route
              path="collections/details/:id"
              element={<EditCollection />}
            />
            <Route
              path="new-collections/details/:id"
              element={<EditCollection />}
            />
            <Route
              path="verify-collections/details/:id"
              element={<EditCollection />}
            />
          
            <Route
              path="verify-collections/verification/:id"
              element={<EditCollection />}
            />
              <Route
              path="verified-collections/details/:id"
              element={<EditCollection />}
            />
              <Route
              path="rejected-collections/details/:id"
              element={<EditCollection />}
            />
             <Route
              path="collectors-collections/details/:id"
              element={<EditCollection />}
            />
          </Route>

          <Route path="settings">
            <Route path="" element={<Settings />}>
              <Route path="" element={<Navigate to="profile" replace />} />
              <Route path="profile" element={<ProfileLayout />}>
                <Route path="" element={<Profile />} />
                <Route path="edit" element={<ProfileEdit />} />
              </Route>
              <Route path="password" element={<Password />} />
            </Route>
          </Route>
          <Route path="frames">
            <Route path="" element={<FrameManagement />}>
              <Route path="" element={<Navigate to="free-frames" replace />} />
              <Route path="free-frames" element={<FreeFramesLayout />}>
                <Route path="" element={<FreeFrames />} />
                <Route path="edit/:id" element={<FreeFramesEdit />} />
              </Route>
              <Route path="premium-frames" element={<PremiumFramesLayout />}>
                <Route path="" element={<PremiumFrames />} />
                <Route path="edit/:id" element={<PremiumFramesEdit />} />
              </Route>
              <Route path="frame-projects" element={<FrameProjectsLayout />}>
                <Route path="" element={<ProjectManagement />} />
                <Route path="add" element={<AddProject />} />
                <Route path="duplicate/:id" element={<AddProject />} />
              </Route>
              <Route path="nft-frames" element={<NFTFramesLayout />}>
                <Route path="" element={<NFTFrames />} />
              </Route>
            </Route>
            <Route path="free-frames/create" element={<CreateFreeFrames />} />
            <Route path="premium-frames/create" element={<CreatePremiumFrames />} />
            <Route path="nft-frames/create" element={<CreateNFTFrames />} />
          </Route>
          <Route path="background-wall">
            <Route path="" element={<BackgroundWall />}>
              <Route path="" element={<Navigate to="wall-multi" replace />} />
              <Route path="wall-mono" element={<Wall2D />} />
              {/* <Route path="wall-meta" element={<Wall3D />} /> */}
              <Route path="wall-multi" element={<Thumbnail />} />
            </Route>
            <Route path="wall-mono/edit/:id" element={<Create2D />} />
            <Route path="wall-mono/create" element={<Create2D />} />
            <Route path="wall-multi/edit/:id" element={<CreateThumbnail />} />
            <Route path="wall-multi/create" element={<CreateThumbnail />} />
          </Route>
          <Route path="gallery-management">
            <Route path="" element={<GalleryManagement />}>
              <Route path="" element={<Navigate to="gallery" replace />} />
              <Route path="gallery" element={<Gallery />} />
              <Route path="gallery/:id" element={<Gallery />} />
              <Route path="creator-gallery" element={<NFTDrop />} />
              <Route path="creator-gallery/:id" element={<NFTDrop />} />
              <Route path="artbank-gallery" element={<ArtBankGallery />} />
              <Route path="artbank-gallery/:id" element={<ArtBankGallery />} />
            </Route>
            <Route path="gallery/details/:id" element={<GalleryDetails />} />
            <Route
              path="creator-gallery/details/:id"
              element={<NFTDropDetails />}
            />
          </Route>
          {/* start of monet reward */}
          <Route path="monet-reward">
            <Route path="" element={<MonetReward />}>
              <Route path="" element={<Navigate to="registration" replace />} />
              <Route path="registration" element={<RegistrationReward />} />
            </Route>
          </Route>
          {/* end of monet reward */}
          <Route path="subscription">
            <Route path="" element={<SubscriptionPlan />}>
              <Route path="" element={<Navigate to="plans" replace />} />
              <Route path="plans" element={<CollectorsPlan />} />
            </Route>
          </Route>
          <Route path="information-desk">
            <Route path="" element={<InformationDeskLayout />}>
              <Route path="" element={<Navigate to="category" replace />} />
              <Route path="category" element={<InformationCategory />} />
              <Route path="terms" element={<Terms />} />
              <Route path="policy" element={<PolicyPrivacy />} />
              <Route path="category/:id" element={<CategoryDetails />} />
              <Route
                path="category/:id/content/create"
                element={<AddContent />}
              />
              <Route
                path="category/:id/content/:subid"
                element={<AddContent />}
              />
            </Route>
          </Route>
          {/* <Route path="project-management">
            <Route path="" element={<ProjectManagement />}>
              <Route
                path=""
                element={<Navigate to="project-management" replace />}
              />
            </Route>
            <Route path="add" element={<AddProject />} />
            <Route path="duplicate/:id" element={<AddProject />} />
          </Route> */}
          <Route path="*" element={<Navigate to="/dashboard" />} />
          {/* <Route path="security">
            <Route path="" element={<Security />}>
              <Route path="" element={<Navigate to="terms" replace />} />
              <Route path="terms" element={<Terms />} />

              <Route path="policy" element={<PolicyPrivacy />} />
            </Route>
          </Route> */}
        </Route>
      </Routes>
    </Suspense>
  );
};
export default Private;
